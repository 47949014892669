import React, { ComponentType, useState, useEffect } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { AccessChecks, NotificationType } from '@digsup/constants';
import { AppContextType } from '@/fe-common/types/contexts';
import { Homepage } from '@digsup/templates-homepage';
import {
  useUser,
  useAppContext,
  useAuthentication,
  usePermissions,
  useNotificationsObserver,
} from '@/fe-hooks';

import { Nav } from './components';
import { AuthenticationContainerProps } from '../authentication';

const AuthenticationContainer: ComponentType<AuthenticationContainerProps> =
  dynamic(
    () => import('../authentication').then(m => m.AuthenticationContainer),
    {
      ssr: false,
    },
  );

type NavigationContainerProps = {
  linksBar?: any;
  isAuthModalOpen?: boolean;
  onCloseAuthModal?: () => void;
  variant?: 'interactive' | 'default';
  logoHref?: string;
  featuresHref?: string;
  pricingHref?: string;
  partnerHref?: string;
  containerClassName?: string;
};

export const NavigationContainer = ({
  linksBar,
  variant = 'default',
  isAuthModalOpen = false,
  onCloseAuthModal,
  logoHref,
  featuresHref,
  pricingHref,
  partnerHref,
  containerClassName = '',
}: NavigationContainerProps) => {
  const { userId }: AppContextType = useAppContext();
  const { userById, getUserById } = useUser();
  const { signOutUser } = useAuthentication();
  const router: any = useRouter();
  const [authIsOpen, setAuthIsOpen] = useState(false);
  const [signUpActive, setSignUp] = useState(false);
  const [showAdminLinks, setShowAdminLinks] = useState(false);
  const { checkAccountPermission } = usePermissions();

  useEffect(() => {
    checkAccountPermission(AccessChecks.AC_CAN_RUN_ADMIN_OPS).then(canRunAdminOps => {
      if (canRunAdminOps?.hasPermission) {
        setShowAdminLinks(true);
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (userId) {
      getUserById(userId);
    }
  }, [userId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isAuthModalOpen) {
      setAuthIsOpen(isAuthModalOpen);
    }
  }, [isAuthModalOpen]);

  useNotificationsObserver(
    [
      NotificationType.NT_17_CLAIMED_NEW_PROPERTY,
      NotificationType.NT_18_CLAIMED_PROPERTY_RELEASED,
      NotificationType.NT_26_PROPERTY_TRACKING_REMOVED,
      NotificationType.NT_27_PROPERTY_TRACKING_ADDED,
      NotificationType.NT_57_COLLABORATION_RECEIVED_INVITE_ACCEPTED,
      NotificationType.NT_75_JOIN_ACCOUNT_RECEIVED_INVITE_ACCEPTED,
      NotificationType.NT_76_CLAIM_ATTEMPT_ON_CLAIMED_PROPERTY,
      NotificationType.NT_102_REMOVED_FROM_PROPERTY_ADMINS,
      NotificationType.NT_110_CLAIM_TRANSFERRED_FROM_OLD_CLAIMANT,
    ],
    () => {
      if (userId) {
        getUserById(userId);
      }
    },
  );

  const signOutHandler = () => {
    // we remove cookies on server side, we should reload document by using window.location.href
    signOutUser().then(() => {
      window.location.href = '/';
    });
  };

  const account = userById?.account;

  let navBar;
  if (userId) {
    navBar = (
      <Nav
        loggedInUser={userById}
        account={account}
        showAdminLinks={showAdminLinks}
        signOutUser={signOutHandler}
        linksBar={linksBar}
        containerClassName={containerClassName}
      />
    );
  } else {
    navBar = (
      <>
        <Homepage.Nav
          variant={variant}
          isAuthenticated={!!userId}
          logoHref={logoHref}
          featuresHref={featuresHref}
          pricingHref={pricingHref}
          partnerHref={partnerHref}
          onLogIn={() => setAuthIsOpen(true)}
        // onSignUp={() => {
        //   setAuthIsOpen(false);
        //   setSignUp(true);
        // }}
        />
      </>
    );
  }

  return (
    <div>
      <AuthenticationContainer
        isOpen={authIsOpen}
        openAuthModal={() => setAuthIsOpen(true)}
        isOpenSignUpInvite={signUpActive}
        onCloseSignUpInvite={() => setSignUp(false)}
        returnToPath={
          router.query?.propertyAddressSlugId
            ? router.query?.propertyAddressSlugId
            : router.query?.returnTo
        }
        onClose={() => {
          setAuthIsOpen(false);
          onCloseAuthModal?.();
        }}
      />
      {navBar}
    </div>
  );
};
