import { format as formatNumber } from 'd3-format';
import { AccountProperty } from '@/fe-common/types/graphql-types';

export const propertyInfo = (accountProperty: AccountProperty) => {
  const { id, property } = accountProperty;
  const {
    createdAt,
    propertyInfo,
    propertyKpiData,
    propertyVersionAddressSlugId
  } = property;

  const availabilityLowValue =
    propertyKpiData?.availabilityPrice
      ?.lowValue > 0
      ? propertyKpiData?.availabilityPrice
        ?.lowValue
      : 0;
  const availabilityHighValue =
    propertyKpiData?.availabilityPrice
      ?.highValue > 0
      ? propertyKpiData?.availabilityPrice
        ?.highValue
      : 0;

  const currentPriceLowValue =
    propertyKpiData?.currentPrice?.lowValue || 0;
  const currentPriceHighValue =
    propertyKpiData?.currentPrice?.highValue || 0;
  const currentPrice =
    currentPriceLowValue && currentPriceHighValue
      ? `$${formatNumber('.2s')(currentPriceLowValue)} - $${formatNumber('.2s')(
        currentPriceHighValue,
      )}`
      : 'Unknown';

  return {
    href: `/property/${propertyVersionAddressSlugId}`,
    address: propertyInfo?.propertyName,
    longTitle: 'Find a Home',
    current: true,
    id: id,
    img:
      propertyInfo?.streetViewUrl ||
      propertyInfo?.satelliteViewUrl,
    availability: `${propertyKpiData?.availability} at ${formatNumber('.2s')(
      availabilityLowValue,
    )} - ${formatNumber('.2s')(availabilityHighValue)}`,
    location: propertyInfo?.propertyCityState,
    price: currentPrice,
    notifications: 0,
    added: createdAt,
  };
};
