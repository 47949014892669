import React from 'react';
import { Nav as BaseNav } from '@digsup/patterns-nav';
import { User, Account } from '@/fe-common/types/graphql-types';
import { propertyInfo } from './property-info';

const nextHomeLinks = [
  {
    href: '/next-home',
    title: 'Listings',
    longTitle: 'Listings',
    current: true,
  },
  {
    href: '/my-property-ratings',
    title: 'Ratings',
    longTitle: 'Ratings History',
  },
  {
    href: '/bookmarks',
    title: 'Bookmarks',
  },
];

const accountsLongLinksList = [
  {
    href: '/dashboard',
    title: 'Dashboard',
  },
  {
    href: '/my-account?page=overview',
    title: 'Overview',
    active: true,
  },
  {
    href: '/my-account?page=preferences',
    title: 'Your Preferences',
  },
  {
    href: '/my-account?page=properties-collaborators',
    title: 'Properties & Collaborators',
  },
  {
    href: '/my-account?page=login-subscription',
    title: 'Login & Subscription',
  },
];

type NavProps = {
  loggedInUser: User;
  account: Account;
  showAdminLinks: boolean;
  signOutUser: () => void;
  linksBar?: any;
  containerClassName?: string;
};

export const Nav = ({
  loggedInUser,
  account,
  showAdminLinks,
  signOutUser,
  linksBar,
  containerClassName = '',
}: NavProps) => {
  const dreamHomes = account?.dreamHomes?.rows || [];
  const claimedProperties = account?.claimedProperties?.rows || [];
  const collaboratingOnProperties = account?.collaboratingOnProperties?.rows || [];
  const nextHome = dreamHomes?.map?.(property => propertyInfo(property)) || [];
  const currentProperties = claimedProperties?.map?.(property => propertyInfo(property)) || [];
  const collaboratingProperties = collaboratingOnProperties?.map?.(property => propertyInfo(property)) || [];
  const accountList = [
    {
      href: '/my-account?page=preferences',
      title: 'Preferences',
    },
    {
      href: '/my-account?page=properties-collaborators',
      title: 'Properties & Collaborators',
      // current: true,
    },
    {
      href: '/my-account?page=login-subscription',
      title: 'Users & Subscrptions',
    },
    {
      onClick: () => signOutUser(),
      title: 'Sign Out',
    },
  ];

  const adminLinksList = showAdminLinks ? [
    {
      href: '/admin/accounts',
      title: 'Accounts',
    },
    {
      href: '/admin/properties',
      title: 'Properties',
    },
    {
      href: '/admin/mail-tracking',
      title: 'Mail Tracking',
    },
    {
      href: '/admin/abuse-reports',
      title: 'Abuse Reports',
    },
  ] : null;

  const accountLicenseImage = account?.currentLicense?.licenseTemplate?.licenseImage;

  return (
    <BaseNav.Container className={containerClassName}>
      <BaseNav logoHref={loggedInUser ? '/dashboard' : '/'}>
        <BaseNav.NextHome
          links={nextHomeLinks}
          data={nextHome}
          href="/next-home"
          current={true}
        />
        <BaseNav.CurrentHome
          data={currentProperties}
          collaboratingOn={collaboratingProperties}
          href="/my-account?page=properties-collaborators"
          claimPropertyHref="/my-account?page=properties-collaborators"
          dataHref={currentProperties?.[0]?.href}
        />
        <BaseNav.Account
          account={{
            ...loggedInUser,
            verified: loggedInUser?.emailVerified,
            type: accountLicenseImage,
          }}
          list={accountList}
          longList={accountsLongLinksList}
          adminLinksList={adminLinksList}
          href="/my-account?page=overview"
          profileHref="/my-account?page=overview"
          signOutUser={signOutUser}
        />
      </BaseNav>
      {linksBar && <BaseNav.LinksBar links={linksBar} />}
    </BaseNav.Container>
  );
};
